@use './functions';
@use './variables';

@mixin responsiveFontSizes($sm, $md, $lg) {
  @media (min-width: 600px) {
    font-size: #{functions.pxToRem($sm)}rem;
  }

  @media (min-width: 900px) {
    font-size: #{functions.pxToRem($md)}rem;
  }

  @media (min-width: 1200px) {
    font-size: #{functions.pxToRem($lg)}rem;
  }
}
