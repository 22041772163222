$primary: #008bde;
$primary-color-hover-light: #3399ff;
$secondary: #d99a04;
$white: #fff;
$hover-primary: #eef6fe;
$background: #f4f6f8;
$off-background: #f1f1f3;
$border-color: rgb(145 158 171 / 0.12);
$text-secondary: #637381;
$nav-section-header: rgb(99, 115, 129);
$card-box-shadow: rgba(145, 158, 171, 0.16) 0px 1px 2px 0px;
$warning: #ba1a1a;
$success: #4caf50;

/* Font Family */
$Roboto-Light: 'Roboto Light';
$Roboto-Light-Italic: 'Roboto Light Italic';
$Roboto: 'Roboto';
$Roboto-Medium: 'Roboto Medium';
$Roboto-Bold: 'Roboto Bold';
