@use '@angular/material' as mat;
@use '../abstracts/mixins';
@use './material_button';
@use './material_menu';
@use '../abstracts/variables' as var;
@use './m3-theme.scss' as m3-theme;
@use './snackbar';
@use './mat-menu';
@include mat.core();

html {
  @include mat.all-component-themes(m3-theme.$light-theme);

  /* Rules for sizing the icon. */
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }

  /* Rules for using icons as black on a light background. */
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }

  mat-icon.warn {
    @include mat.icon-color(m3-theme.$light-theme, $color-variant: error);
  }

  mat-icon.primary {
    @include mat.icon-color(m3-theme.$light-theme, $color-variant: primary);
  }

  .mdc-button.warn {
    @include mat.button-color(m3-theme.$light-theme, $color-variant: error);
  }
}

mat-drawer {
  @include mat.all-component-colors(m3-theme.$dark-theme);
}
