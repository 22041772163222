@use '../abstracts/variables';

.profile-menu {
  .info {
    margin: 0.25rem;
    border: 1px solid variables.$primary;
    border-radius: 5px;
    padding: 0.25rem;
  }
}
