@use './base/reset';
@use './themes/material';
@use './abstracts/variables' as var;
@use './base/typography';
@use './base/normalize';
@use './abstracts/mixins';
@use './abstracts/functions';

body {
  background-color: var.$background;
}

h1 {
  font-family: var.$Roboto-Bold;
  font-weight: 700;
  @include mixins.responsiveFontSizes(52, 58, 64);
}

h2 {
  font-family: var.$Roboto-Bold;
  font-weight: 700;
  @include mixins.responsiveFontSizes(40, 44, 58);
}

h3 {
  line-height: 1.5;
  font-family: var.$Roboto-Bold;
  font-weight: 700;
  @include mixins.responsiveFontSizes(26, 30, 32);
}

h4 {
  font-family: var.$Roboto-Bold;
  line-height: 1.5;
  @include mixins.responsiveFontSizes(20, 24, 24);
}

h5 {
  line-height: 1.5;
  font-family: var.$Roboto-Bold;
  font-weight: 700;
  @include mixins.responsiveFontSizes(20, 21, 22);
}

h6 {
  font-family: var.$Roboto-Bold;
  font-weight: 700;

  @include mixins.responsiveFontSizes(18, 18, 18);
}

.ft-sm {
  font-size: 14px;
}

.roboto-medium {
  font-family: var.$Roboto-Medium;
}

.roboto-bold {
  font-family: var.$Roboto-Bold;
}
