@use '../abstracts/variables';
.mdc-snackbar:not(.success-toast, .error-toast) {
}

.success-toast {
  --mdc-snackbar-container-color: #{variables.$success};
  --mdc-snackbar-supporting-text-color: #{variables.$white};
}

.error-toast {
  --mdc-snackbar-container-color: #{variables.$warning};
  --mdc-snackbar-supporting-text-color: #{variables.$white};
}
